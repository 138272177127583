<template>
    <a-layout>
        <div class="yj-conten" style="background-color:#fff;min-width:max-content">
            <a-layout-content style="padding:0;">
                <div class="yj-conten" style=" background: #fff; padding:0px 20px 0; margin:0;">
                    <!--<a-row class="yj-form" style="padding-left:20px; padding-bottom:0px;">
                        <a-col :span="24">
                            <label>佣金管理：</label>
                            <a-radio-group :default-value="Commission" @change="radioChange">
                                <a-radio-button value="0">订单</a-radio-button>
                                <a-radio-button value="1">合伙人</a-radio-button>
                            </a-radio-group>
                        </a-col>
                    </a-row>-->
                    <a-form layout="horizontal" style="padding:10px 0px 0;height:auto;">
                        <a-form-item style="margin-bottom:10px;">
                            <a-form layout="inline">
                                <a-form-item>
                                    <a-tabs default-active-key="0" @change="radioChange">
                                        <a-tab-pane key="0" tab="订单" />
                                        <a-tab-pane key="1" tab="团队" />
                                    </a-tabs>
                                </a-form-item>
                            </a-form>
                        </a-form-item>
                    </a-form>
                    <div v-if="Commission==0">
                        <SettlementList />
                    </div>
                    <div v-if="Commission==1">
                        <PartnerList />
                    </div>
                </div>
            </a-layout-content>
        </div>
    </a-layout>
</template>
<script>
    import PartnerList from "@/Views/PartnerModule/Commission/PartnerList";
    import SettlementList from "@/Views/PartnerModule/Commission/SettlementList";
    export default {
        name: "",
        data() {
            return {
                Commission: "0", formLayout: 'horizontal',
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 2 }
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 24 }
                    }
                },
            };
        },
        props: {//组件属性
        },
        methods: {//方法
            radioChange: function (item) {
                var self = this;
                self.Commission = item;
            },
            handleFormLayoutChange(e) {
                this.formLayout = e.target.value;
            },
        },
        computed: {//计算属性
        },
        watch: {//侦听属性
        },
        components: {//组件
            PartnerList,
            SettlementList
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>