<template>
    <a-layout-content style="padding-bottom:10px;">
        <a-layout style="background:#fff;">
            <a-layout-header style="height:initial;background:#fff;padding: 0;">
                <!--<a-row>
                    <a-col :span="19">
                        <label style="margin-right:10px;">结算状态：</label>
                        <a-radio-group :default-value="SettleStatus" @change="radioChange">
                            <a-radio-button value="-1">全部</a-radio-button>
                            <a-radio-button value="0">未结算</a-radio-button>
                            <a-radio-button value="1">已结算</a-radio-button>
                            <a-radio-button value="2">已撤销</a-radio-button>
                        </a-radio-group>
                        <a-form layout="horizontal" style="padding:10px  0px;height:auto;">
                            <a-form layout='inline'>
                                <label style="margin-right:10px;">业务单号：</label>
                                <a-input placeholder="业务单号" style="width:280px;margin-right:10px;" @change="name_change" />
                                <a-button type="primary" class="margin-r" @click="search">
                                    查询
                                </a-button>
                                <a-button type="primary" @click="exportdata">
                                    导出
                                </a-button>
                            </a-form>
                        </a-form>
                    </a-col>
                    <a-col :span="5" class="textal_c">
                        <a-upload name="file"
                                  :showUploadList="false"
                                  action="/FileServer/Upload"
                                  :data="ParamData"
                                  accept=".xls,.xlsx"
                                  @change="imgChange">
                            <a-button> <a-icon type="upload" />导入线上结算</a-button>
                        </a-upload>
                        <a-button type="primary" ghost @click="exportsettlement" style="margin-left:10px;">
                            导出线下结算
                        </a-button>
                    </a-col>
                </a-row>-->
                <a-form layout="horizontal" style="padding: 0 0 10px 0px;height:auto;">
                    <a-form-item style="margin-bottom:10px;">
                        <a-form layout="inline">
                            <a-form-item>
                                <label class="yj-partner-check_lab">结算状态：</label>
                                <a-radio-group :default-value="SettleStatus" @change="radioChange">
                                    <a-radio-button value="-1">全部</a-radio-button>
                                    <a-radio-button value="0">未结算</a-radio-button>
                                    <a-radio-button value="1">已结算</a-radio-button>
                                    <a-radio-button value="2">已撤销</a-radio-button>
                                </a-radio-group>
                            </a-form-item>
                        </a-form>

                    </a-form-item>
                    <a-form-item style="margin-bottom:10px;">
                        <a-form layout="inline">
                            <a-form-item>
                                <label class="yj-partner-check_lab">业务单号：</label>
                                <a-input placeholder="业务单号" style="width:260px;" @change="name_change" />
                            </a-form-item>
                            <a-form-item>
                                    <label class="yj-partner-check_lab">用户：</label>
                                    <a-input placeholder="用户" style="width:260px;" v-model="UserName" />
                                </a-form-item>
                                <a-form-item>
                                    <label class="yj-partner-check_lab">介绍人：</label>
                                    <a-input placeholder="介绍人" style="width:260px;" v-model="PartnerName" />
                                </a-form-item>
                            <a-form-item>
                                <a-button type="primary" class="margin-r" @click="search">
                                    查询
                                </a-button>
                                <a-button class="margin-r" @click="exportdata">
                                    导出
                                </a-button>
                            </a-form-item>
                            <a-form-item>
                                <a-upload name="file"
                                          :showUploadList="false"
                                          action="/FileServer/Upload"
                                          :data="ParamData"
                                          accept=".xls,.xlsx"
                                          @change="imgChange">
                                    <a-button class="margin-r"> <a-icon type="upload" />导入结算</a-button>
                                </a-upload>
                                <a-button @click="exportsettlement">
                                    导出线下结算
                                </a-button>
                            </a-form-item>
                        </a-form>

                    </a-form-item>
                </a-form>
            </a-layout-header>
            <a-layout-content>
                <div class="yj_list">
                    <a-table :columns="columns"  bordered
                             rowKey="ID"
                             :data-source="tableData"
                             :pagination="false"
                             @change="pagerChange"
                             class="yj-table-img" style=" min-height:calc(100vh - 364px)"
                             :row-selection="rowSelection">
                            <span slot="UserName" slot-scope="text, record">
                                <a @click="UserDetail(record.UserID)">{{record.UserName }}</a>
                            </span>
                            <span slot="ProductName" slot-scope="text, record">
                                {{record.ProductName }}
                            </span>
                            <span slot="IntroduceName" slot-scope="text, record">
                                <a @click="Partner(record.IntroduceID)">{{record.IntroduceName }}</a>
                            </span>
                        <span slot="name" slot-scope="text, record">
                            <a @click="PartnerDetail(record)">{{record.BusinessID }}</a>
                        </span>
                        <span slot="SettleStatus" slot-scope="text, record">{{ ShowEnum2(record.SettleStatus) }}</span>
                        <span slot="SettleMode" slot-scope="text, record">{{record.SettleStatus==1?ShowEnum3(record.SettleMode):'' }}</span>
                    </a-table>
                </div>
                <div style="padding:10px 0 ">
                    <a-row type="flex" justify="space-between">
                        <a-col :span="3">
                            <a-button type="primary" class="margin-r" ghost @click="batchgrant">
                                批量结算
                            </a-button>
                            <a-modal title="批量结算"
                                     :visible="visible"
                                     @ok="handleOk"
                                     @cancel="handleCancel"
                                     okText="确认"
                                     cancelText="取消">
                                <p>
                                    <a-radio-group v-model="SettleMode">
                                        <a-radio :value="1">
                                            线下
                                        </a-radio>
                                        <a-radio :value="0">
                                            线上
                                        </a-radio>
                                    </a-radio-group>
                                    <a-input placeholder="备注~" v-model="Remark" type="textarea" style="margin-top:20px;" />
                                </p>
                            </a-modal>

                        </a-col>
                        <a-col :span="12" class="textal_r">
                            <a-pagination :total="pagination.total"
                                          show-quick-jumper
                                          v-model="pagination.current"
                                          @change="pagerChange"></a-pagination>
                        </a-col>

                    </a-row>
                </div>
            </a-layout-content>
        </a-layout>
    </a-layout-content>
</template>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    export default {
        name: "",
        data() {
            return {
                visible: false,
                Remark: '',
                SettleMode: 1,
                SettleStatus: "0",
                columns: [
                    {
                        title: "业务单号",
                        width: 200,
                        scopedSlots: { customRender: 'name' }
                    },
                    {
                        title: "用户",
                        width: 120,
                        scopedSlots: { customRender: 'UserName' }
                    },
                    {
                        title: "商品名称",
                        width: 200,
                        scopedSlots: { customRender: 'ProductName' }
                    },
                    {
                        title: "介绍人",
                        width: 100,
                        scopedSlots: { customRender: 'IntroduceName' }
                    },
                    
                    {
                        title: "金额",

                        width: 100,
                        customRender: (text, item) => {
                            return util.ToFixed(item.SettleMoney);
                        }
                    },
                    {
                        title: "结算状态",
                        width: 100,
                        align: "center",
                        scopedSlots: { customRender: 'SettleStatus' }
                    },
                    {
                        title: "结算方式",
                        width: 120,
                        align: "center",
                        scopedSlots: { customRender: 'SettleMode' }
                    },
                    {
                        title: "日期",
                        align: "left",
                        width: 200,
                        customRender: (text, item) => {
                            return util.TimeStamptoDateTime(item.InputTime, 'yyyy-MM-dd hh:mm:ss')
                        }
                    },
                    {
                        title: "结算日期",
                        align: "left",
                        width: 200,
                        customRender: (text, item) => {
                            return util.TimeStamptoDateTime(item.SettleTime, 'yyyy-MM-dd hh:mm:ss')
                        }
                    },
                    {
                        title: "摘要",

                        width: 200,
                        dataIndex: "Abstract"
                    },
                ],
                tableData: [],
                pagination: {
                    total: 0,
                    defaultPageSize: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                },
                name: "",
                selectedRowKeys: [],
                ParamData: {
                    ProcessorName: "settlement",
                    JsonDataParams: ""
                },
                jsondata2: [],
                jsondata3: [],
                UserName:"",//用户名称
                PartnerName:"",//介绍人名称
            };
        },
        props: {//组件属性
        },
        methods: {//方法
            handleOk() {
                var self = this;
                var op = {
                    url: "/PartnerModule/PartnerSettlement/Settle",
                    data: {
                        SettleOrderID: self.selectedRowKeys,
                        SettleMode: self.SettleMode,
                        Remark: self.Remark,
                    },
                    OnSuccess: function (data) {
                        console.log(data);
                        self.visible = false;
                        self.pagination.current = 1;
                        self.getTableData();
                        self.selectedRowKeys = '';
                        self.$message.success("佣金结算成功");
                    }
                };
                http.Post(op);
            },
            handleCancel() {
                var self = this;
                self.visible = false;
            },
            name_change: function (item) {
                var self = this;
                self.name = item.target.value;
            },
            search: function () {
                var self = this;
                self.pagination.current = 1;
                self.getTableData();
            },
            pagerChange: function (item) {
                var self = this;
                self.pagination.current = item;
                self.getTableData();
            },
            Partner(e) {
                var self = this;
                self.$router.push({
                    name: "PartnerList_PartnerDetail",
                    query: {
                        ID: e
                    }
                });
            },
            UserDetail(e){
                var self = this;
                self.$router.push({
                    name: "PartnerList_PartnerDetail",
                    query: {
                        ID: e
                    }
                });
            },
            getTableData: function () {
                var self = this;
                var op = {
                    url: "/PartnerModule/PartnerSettlement/GetUserSettlementList",
                    data: {
                        pageIndex: self.pagination.current,
                        name: self.name,
                        SettleStatus: self.SettleStatus,
                        UserName:self.UserName,
                        IntroduceName:self.PartnerName,
                    },
                    OnSuccess: function (data) {
                        self.tableData = data.data.Data;
                        self.pagination.total = data.data.Total;
                        self.pagination.defaultPageSize = data.data.RowCount;
                        self.pagination.current = data.data.PageIndex;
                    }
                };
                http.Post(op);
            },
            exportdata: function () {
                var self = this;
                var op = {
                    url: "/PartnerModule/PartnerSettlement/GetUserSettlementExcel",
                    data: {
                        name: self.name,
                        SettleStatus: self.SettleStatus,
                        isOffline: false//  正常导出
                    },//下载接口参数
                    FileName: '订单.xlsx',//下载文件重命名
                    ContentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'//文件Mine类型
                };
                http.DownLoadFile(op);
            },
            imgChange: function (data) {
                var self = this;
                if (data.file.response) {
                    if (data.file.response.Message) {
                        this.$message.error(data.file.response.Message);
                    } else {
                        self.pagination.current = 1;
                        self.getTableData();
                        const h = this.$createElement;
                        this.$info({
                            title: '提示',
                            content: h("div", null, [
                                h("p", null, "文件导入完成，"),
                                h("p", null, "成功" + data.file.response.Success + "条，失败" + data.file.response.Fail + "条"),
                                h("P", null, [
                                    h(
                                        "a",
                                        { attrs: { href: data.file.response.ResultUrl, target: "_blank" } },
                                        "下载导入结果"
                                    )
                                ])
                            ]),
                            okText: "确定"
                        });
                    }
                }
            },
            exportsettlement: function () {
                var self = this;
                var op = {
                    url: "/PartnerModule/PartnerSettlement/GetUserSettlementExcel",
                    data: {
                        name: self.name,
                        SettleStatus: 0,
                        isOffline: true //线下导出结算
                    },//下载接口参数
                    FileName: '订单.xlsx',//下载文件重命名
                    ContentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'//文件Mine类型
                };
                http.DownLoadFile(op);
            },
            batchgrant: function () {
                var self = this;
                if (self.selectedRowKeys == '') {
                    this.$info({
                        title: '提示',
                        content: '请选择订单！',
                        okText: "确定"
                    });
                } else {
                    self.visible = true;
                }
            },
            PartnerDetail(e) {
                if (e.SettleType == 0) {
                    this.$router.push({
                        name: "Order_OrderDetail",
                        query: { ID: e.BusinessID }
                    });
                } else {
                    this.$router.push({
                        name: "Order_DetailReward",
                        query: { ID: e.BusinessID }
                    });
                }
            },
            radioChange: function (item) {
                var self = this;
                self.SettleStatus = item.target.value;
                self.pagination.current = 1;
                self.getTableData();
            },
            getData3: function () {//获取枚举信息
                var Self = this;
                var op = {
                    url: "/Enum",
                    data: {
                        AssemblyName: "Welcome.PartnerModule",//程序集名称
                        TypeName: "Welcome.PartnerModule.Enumerate.SettlementStatusEnum"//枚举类型名称。要带上命名空间的全称
                    },
                    OnSuccess: function (response) {
                        Self.jsondata2 = response.data
                    }
                }
                http.Post(op);
            },
            ShowEnum2: function (Key) {//枚举不是从0开始，或不连续，可以使用此方法
                var Self = this;
                var item = Self.jsondata2.find(function (item) {
                    return item.ID == Key;
                });
                if (item)
                    return item.Name
                else
                    return "";
            },
            getData4: function () {//获取枚举信息
                var Self = this;
                var op = {
                    url: "/Enum",
                    data: {
                        AssemblyName: "Welcome.PartnerModule",//程序集名称
                        TypeName: "Welcome.PartnerModule.Enumerate.SettlementModeEnum"//枚举类型名称。要带上命名空间的全称
                    },
                    OnSuccess: function (response) {
                        Self.jsondata3 = response.data
                    }
                }
                http.Post(op);
            },
            ShowEnum3: function (Key) {//枚举不是从0开始，或不连续，可以使用此方法
                var Self = this;
                var item = Self.jsondata3.find(function (item) {
                    return item.ID == Key;
                });
                if (item)
                    return item.Name
                else
                    return "";
            }
        },
        computed: {//计算属性
            rowSelection() {
                return {
                    onChange: (selectedRowKeys, selectedRows) => {
                        var self = this;
                        self.selectedRowKeys = selectedRowKeys;
                        console.log(selectedRows);
                    },
                    getCheckboxProps: record => ({
                        props: {
                            disabled: record.name === 'Disabled User', // Column configuration not to be checked
                            name: record.name,
                        },
                    }),
                };
            }
        },
        watch: {//侦听属性
        },
        components: {//组件
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
            this.getData4();
            this.getData3();
            this.getTableData();
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>