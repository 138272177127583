<template>
    <a-layout-content style="padding-bottom:10px;">
        <a-layout style="background:#fff;">
            <a-layout-header style="height:initial;background:#fff;padding:0 ;">
                <!--<a-row>
                    <a-col :span="19">
                        <a-form layout="horizontal" style="padding:10px  0px;height:auto;">
                            <a-form layout='inline'>
                                <a-form-item label="合伙人 ">
                                    <a-input placeholder="姓名/电话/昵称" style="width:280px;" @change="name_change" />
                                </a-form-item>
                                <a-form-item label="">
                                    <a-button type="primary" class="margin-r" @click="search">
                                        查询
                                    </a-button>
                                    <a-button type="primary" @click="exportdata">
                                        导出
                                    </a-button>
                                </a-form-item>
                            </a-form>
                        </a-form>
                    </a-col>
                </a-row>-->
                <a-form layout="horizontal" style="padding: 0 0 10px 0px;height:auto;">
                    <a-form-item style="margin-bottom:10px;">
                        <a-form layout="inline">
                            <a-form-item>
                                <label class="yj-partner-check_lab">团队：</label>
                                <a-input placeholder="姓名" style="width:260px;" @change="name_change" />
                            </a-form-item>
                            <a-form-item>
                                <a-button type="primary" class="margin-r" @click="search">
                                    查询
                                </a-button>
                                <a-button @click="exportdata">
                                    导出
                                </a-button>
                            </a-form-item>
                        </a-form>

                    </a-form-item>
                </a-form>
            </a-layout-header>
            <a-layout-content>
                <div class="yj_list">
                    <a-table :columns="columns" bordered
                             rowKey="ID"
                             :data-source="tableData"
                             :pagination="false"
                             @change="pagerChange"
                             class="yj-table-img" style=" min-height:calc(100vh - 314px)">
                        <span slot="action" slot-scope="text, record">
                            <!-- <a @click="editRow(record)">详情</a> -->
                            <a @click="DetailRow(record)">明细</a>
                        </span>
                        <span slot="name" slot-scope="text, record">
                            <a @click="PartnerDetail(record)" style="cursor:pointer;">
                                <img :src="record.UHeadImg" width="50" height="50" style="float: left;margin-right: 10px;border-radius:50%;" />
                                <p>
                                    {{record.Name }}<br />
                                    <label style="font-size:12px;cursor:pointer;">{{record.Phone = record.Phone.length != 11 ? "" : record.Phone }}</label>
                                </p>
                            </a>
                        </span>
                    </a-table>
                </div>
                <div style="padding:10px 0 ; ">
                    <a-row type="flex" justify="space-between">

                        <a-col :span="24" class="textal_r">
                            <a-pagination :total="pagination.total"
                                          show-quick-jumper
                                          v-model="pagination.current"
                                          @change="pagerChange"></a-pagination>
                        </a-col>

                    </a-row>
                </div>
            </a-layout-content>
        </a-layout>
    </a-layout-content>
</template>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    export default {
        name: "",
        data() {
            return {
                columns: [
                    {
                        title: "团队信息",
                        width: 360,
                        scopedSlots: { customRender: 'name' },
                    },
                    {
                        title: "待提现",
                        width: 200,
                        align: "center",
                        customRender: (text, item) => {
                            return <div class="textcl_orange">{util.ToFixed(item.WithdrawalMoney)}</div>;
                        }
                    },
                    {
                        title: "累计",
                        align: "center",
                        width: 200,
                        customRender: (text, item) => {
                            return util.ToFixed(item.CumulativeMoney);
                        }
                    },
                    {
                        title: '操作',
                        align: "center",
                        width: 300,
                        scopedSlots: { customRender: 'action' },
                    }
                ],
                tableData: [],
                pagination: {
                    total: 0,
                    defaultPageSize: 0,
                    current: 1,
                    showTotal: total => `共 ${total} 条数据`
                },
                name: "",
            };
        },
        props: {//组件属性
        },
        methods: {//方法
            name_change: function (item) {
                var self = this;
                self.name = item.target.value;
            },
            search: function () {
                var self = this;
                self.pagination.current = 1;
                self.getTableData();
            },
            pagerChange: function (item) {
                var self = this;
                self.pagination.current = item;
                self.getTableData();
            },
            getTableData: function () {
                var self = this;
                var op = {
                    url: "/PartnerModule/PartnerAccounts/GetPartnerAccountsList",
                    data: {
                        pageIndex: self.pagination.current,
                        name: self.name,
                    },
                    OnSuccess: function (data) {
                        self.tableData = data.data.Data;
                        self.pagination.total = data.data.Total;
                        self.pagination.defaultPageSize = data.data.RowCount;
                        self.pagination.current = data.data.PageIndex;
                    }
                };
                http.Post(op);
            },
            exportdata: function () {
                var self = this;
                var op = {
                    url: "/PartnerModule/PartnerAccounts/GetPartnerAccountsListExcel",
                    data: {
                        name: self.name
                    },//下载接口参数
                    FileName: '团队列表.xlsx',//下载文件重命名
                    ContentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'//文件Mine类型
                };
                http.DownLoadFile(op);
            },
            editRow(e) {
                this.$router.push({
                    name: "Commission_PartnerDetail",
                    query: { id: e.UserID }
                });
            },
            DetailRow(e) {
                this.$router.push({
                    name: "Commission_PartnerAccountDetail",
                    query: { id: e.UserID }
                });
            },
            PartnerDetail(e) {
                this.$router.push({
                    name: "PartnerList_PartnerDetail",
                    query: { ID: e.UserID }
                });
            }
        },
        computed: {//计算属性

        },
        watch: {//侦听属性
        },
        components: {//组件
        },
        beforeCreate: function () {//生命周期，创建前
        },
        created: function () {//生命周期，创建完成
        },
        beforeMount: function () {//生命周期，组装前
        },
        mounted: function () {//生命周期，组装完成
            this.getTableData();
        },
        beforeUpdate: function () {//生命周期，data更新前
        },
        updated: function () {//生命周期，data更新完成
        },
        beforeDestroy: function () {//生命周期，销毁前
        },
        destroyed: function () {//生命周期，销毁完成
        }
    };
</script>